import * as React from "react"

import Seo from "../components/seo"

// Temporary Data
import commonData from "../data/common-data.json"
import propertyDetails from "../data/property-details.json"

// Components
import Header from "../components/Header"
import BreadCrumbs from "../components/BreadCrumbs"
import PropertyOverview from "../layout/PropertyDetails/PropertyOverview"
import Description from "../layout/PropertyDetails/Description"
import BroadbandDetails from "../layout/PropertyDetails/BroadbandDetails"
import LocationDetails from "../layout/PropertyDetails/LocationDetails"
import AreaGuide from "../layout/PropertyDetails/AreaGuide"
import SimilarProperties from "../layout/PropertyDetails/SimilarProperties"
import Footer from "../components/Footer"

const breadCrumbsData = [
    {
        name: 'Home',
        link: '#'
    },
    {
        name: 'Somerset Court, W8, Kensington',
        link: '#'
    },
    {
        name: '1 Bedroom Apartment Somerset Court, W8, Kensington',
        link: '#'
    },
];

const PropertyDetails = () => {
    return (
        <>
            <Seo title="Property Details" />
            <div className="page-wrapper">
                <Header headerData={ commonData } />
                <BreadCrumbs
                    breadCrumbsData={ breadCrumbsData }
                />
                <PropertyOverview />
                <Description />
                <BroadbandDetails />
                <LocationDetails />
                <AreaGuide />
                <SimilarProperties similarPropertiesData={ propertyDetails.SimilarProperties } />
                <Footer
                    footerData={ commonData.FooterMenu }
                    footerTermsMenu={ commonData.FooterTermsMenu }
                    footerSocialLinks={ commonData.FooterSocialLinks }
                    footerCompanies={ commonData.FooterCompanies }
                />
            </div>
        </>
    )
}

export default PropertyDetails
